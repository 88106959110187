import React from "react";
import classNames from "classnames";

const Layout: React.FC<{
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  layoutClassName?: string;
}> = ({ children, footer, header, className, layoutClassName }) => (
  <div
    className={classNames(
      "relative flex min-h-screen flex-col",
      layoutClassName,
    )}
  >
    {header && <div className="sticky top-0 z-30">{header}</div>}
    <div className={classNames("flex-1 bg-grey-6", className)}>{children}</div>
    {footer && <div className="sticky bottom-0 z-30 bg-grey-6">{footer}</div>}
  </div>
);

export default Layout;
