import React, { createContext, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  RetailerFragment,
  RetailerQuery,
  RetailerQueryVariables,
  DefaultRetailerQuery,
  DefaultRetailerQueryVariables,
} from "../services/graphql/operations-types";
import NotFound from "../deprecated/organisms/not-found";
import Loading from "../deprecated/organisms/loading";
import useFetchJson from "./use-fetch-json";

const RETAILER_FRAGMENT = gql`
  fragment Retailer on Retailer {
    id
    hostname
    name
    primaryColor
    faviconUrl
    metaTitle
    metaDescription
    logoUrl
    lottieLoadingAnimationUrl
    homeIsEnabled
    termsOfServiceUrl
    privacyPolicyUrl
    storeClerkPrivacyPolicyUrl
    legalNoticeUrl
    cookiesPolicyUrl
    askServiceIncentiveFactor
    localeOverrides {
      key
      value
    }
    enableUserSelection
    customerSatisfactionRequest
  }
`;

export const RETAILER_QUERY = gql`
  ${RETAILER_FRAGMENT}

  query Retailer($hostname: String!) {
    retailer(hostname: $hostname) {
      id
      ...Retailer
    }
  }
`;

const DEFAULT_RETAILER_QUERY = gql`
  ${RETAILER_FRAGMENT}

  query DefaultRetailer {
    defaultRetailer {
      ...Retailer
    }
  }
`;

type ExtraRetailerFields = {
  lottieLoadingAnimation: unknown;
};
const RetailerContext = createContext<
  (RetailerFragment & ExtraRetailerFields) | null
>(null);

type ExtraProviderProps = {
  children: React.ReactNode;
  retailer: RetailerFragment;
};
const ExtraProvider = ({ children, retailer }: ExtraProviderProps) => {
  const useFetchLoadingAnimationDataJsonResult = useFetchJson(
    retailer.lottieLoadingAnimationUrl,
  );

  if (useFetchLoadingAnimationDataJsonResult.state === "LOADING") {
    return <Loading />;
  }

  return (
    <RetailerContext.Provider
      value={{
        ...retailer,
        lottieLoadingAnimation:
          useFetchLoadingAnimationDataJsonResult.state !== "ERROR"
            ? useFetchLoadingAnimationDataJsonResult.data
            : null,
      }}
    >
      {children}
    </RetailerContext.Provider>
  );
};

type DefaultRetailerProviderProps = {
  children: React.ReactNode;
};
const DefaultRetailerProvider = ({
  children,
}: DefaultRetailerProviderProps) => {
  const { data, error } = useQuery<
    DefaultRetailerQuery,
    DefaultRetailerQueryVariables
  >(DEFAULT_RETAILER_QUERY);

  if (error) {
    throw error;
  }

  if (!data) {
    return <Loading />;
  }

  if (!data.defaultRetailer) {
    return <NotFound />;
  }

  return (
    <ExtraProvider retailer={data.defaultRetailer}>{children}</ExtraProvider>
  );
};

type RetailerProviderProps = {
  children: React.ReactNode;
};
export const RetailerProvider = ({ children }: RetailerProviderProps) => {
  const { data, error } = useQuery<RetailerQuery, RetailerQueryVariables>(
    RETAILER_QUERY,
    {
      variables: { hostname: window.location.hostname },
    },
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return <Loading />;
  }

  if (!data.retailer) {
    return <DefaultRetailerProvider>{children}</DefaultRetailerProvider>;
  }

  return <ExtraProvider retailer={data.retailer}>{children}</ExtraProvider>;
};

export const useRetailer = () => {
  const retailer = useContext(RetailerContext);

  if (!retailer) {
    throw new Error(
      `Retailer context not loaded, you probably forgot to inject RetailerProvider in your React tree`,
    );
  }

  return retailer;
};
